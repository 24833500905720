<template>
  <div class="flex-1 md:flex md:overflow-y-hidden">

    <div class="md:flex-1 md:flex md:space-x-4">

      <div class="p-4 text-sm bg-white rounded md:w-72 md:overflow-y-auto">

        <router-link
          v-if="parent"
          class="flex items-center text-green-500 underline"
          :to="{
            name: getRouteName(parent.type),
            params: {
              id: parent.id
            }
          }"
        >
          <FontAwesomeIcon :icon="['far', 'arrow-left']" />
          <div class="ml-2">{{ parent.title }}</div>
        </router-link>

        <router-link
          v-else-if="current"
          class="flex items-center text-green-500 underline"
          :class="{
            'mb-8': children.length > 0
          }"
          :to="{
            name: getRouteName(current.type)
          }"
        >
          <FontAwesomeIcon :icon="['far', 'arrow-left']" />
          <div class="ml-2">
            <PTranslation k="Common.General.Back" />
          </div>
        </router-link>

        <div class="hidden md:block">

          <ul v-if="children.length > 0">
            <li
              v-for="(child, index) in children"
              :key="index"
            >
              <router-link
                class="flex items-center text-green-500 underline"
                :to="{
                  name: getRouteName(child.type),
                  params: {
                    id: child.id
                  }
                }"
              >
                {{ child.title }}
              </router-link>
            </li>
          </ul>

        </div>

        <div class="md:hidden">

          <PSelect
            :items="children"
            itemText="title"
            itemValue="id"
            placeholder="Meny"
            v-model="selectedChild"
            v-if="children.length > 0"
          />


        </div>

      </div>

      <div class="p-4 mt-4 overflow-y-auto bg-white rounded md:mt-0 md:flex-1">

        <template v-if="current">

          <!-- {{ current }} -->

          <h1
            class="text-2xl font-medium"
            v-html="current.title"
          ></h1>
          <div class="text-sm text-gray-500">{{ current.created | date }}</div>
          <div
            class="pb-4 mt-2 mb-4 font-medium border-b border-gray-200"
            v-html="current.abstract"
          ></div>
          <div
            class="text-sm rte"
            v-html="current.text"
          ></div>

        </template>

        <template v-else-if="children.length > 0">

          <div
            class="mb-6"
            v-for="(child, index) in children"
            :key="index"
          >

            <RouterLink
              class="group"
              v-if="!child.parentId"
              :to="{
                name: getRouteName(child.type),
                params: {
                  id: child.id
                }
              }"
            >
              <h2 class="text-sm font-semibold text-green-600 underline group-hover:text-black">{{ child.title }}</h2>
              <div class="text-xs">{{ child.created | date }} / {{ child.createrName }}</div>
              <p class="mt-1 text-sm">{{ child.abstract }}</p>
            </RouterLink>

          </div>

        </template>

      </div>

    </div>

  </div>

</template>

<script>

import http from "@/utilities/http"
import { get } from "lodash"
import { mapGetters } from "vuex"

export default {

  props: {
    id: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      parent: null,
      current: null,
      children: [],
      selectedChild: ""
    }
  },

  computed: {

    ...mapGetters(["location"]),

    allProps() {
      return {
        id: this.id,
        type: this.type,
        // location: this.location
      }
    },
  },

  methods: {
    getRouteName(type) {
      switch (type) {
        case "Message": return "messages.Messages"
        case "Article": return "messages.Articles"
        case "Guide": return "messages.Guides"
        case "System": return "messages.System"
        default: return ""
      }
    },
    async load() {
      this.isLoading = true

      try {
        let parent
        let current
        let children = []

        if (this.id) {
          current = await http.get("Message", {
            params: {
              id: this.id
            }
          })

          if (current.parentId) {
            parent = await http.get("Message", {
              params: {
                id: current.parentId
              }
            })
          }
          children = await http.get("Messages", {
            params: {
              parentId: this.id
            }
          })
        } else {
          children = await http.get("Messages", {
            params: {
              parentIdSet: false,
              type: this.type
            }
          })
        }

        this.parent = parent
        this.current = current
        this.children = children
        // reset this one
        this.selectedChild = ""

        const isRead = get(current, "isRead", "")

        if (!isRead) {
          await http.post("MessageRead", {
            id: current.id,
            read: true
          })
          this.$store.dispatch("getAlerts")
        }

      }
      catch (error) {
        this.$appInsights.trackException({ exception: error })
      }

      this.isLoading = false
    },

  },
  watch: {
    allProps: {
      handler: async function() {
        await this.load()
      },
      immediate: true
    },
    selectedChild: async function(id) {
      if (id) {
        try {
          const message = await http.get("Message", {
            params: { id }
          })

          if (message) {
            this.$router.push({
              name: this.getRouteName(message.type), params: {
                id: message.id
              }
            })
          }

        } catch (error) {
          this.$appInsights.trackException({ exception: error })
        }
      }
    }
  }
}
</script>